import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  sideBarExpandButton,
  sideBarContainer,
  logoContainer,
  menuItem,
  menuItemActive,
  menuItemCollapsed,
  menuItemActiveCollapsed,
  iconSx,
  wrapperMenuItemTop,
  innerMenuItemTop,
} from "./styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import logo from "../../assets/images/logo_filled.svg";
import auth from "../../auth/auth-helper";
import Loader from "../Loader";
import { setSideBarIsOpen } from "../../role/roleSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button, Typography, Box, Grid, Switch, Popover } from "@mui/material";
import { setPresentationMode } from "../../parametersConstancy/platformParametersSlice";
import { useGetSidebarData } from "./useGetSidebarData";

function Sidebar({ setFeedbackOpen }) {
  const SidebarData = useGetSidebarData();
  const matches = useMediaQuery("(min-width:1280px)");
  const dispatch = useDispatch();
  const [sideBar, setSideBar] = useState(false);
  const [currentChildren, setCurrentChildren] = useState([]);
  const navigate = useNavigate();
  let location = useLocation();
  const isPresentationModeEnabled = useSelector(
    (state) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.isPresentationModeEnabled,
  );
  let jwt = auth.isAuthenticated();

  let anchorRef = useRef(null);

  const showSideBar = () => {
    dispatch(setSideBarIsOpen(!sideBar));
    setSideBar(!sideBar);
  };

  // const isActive = (location, path) => {
  //   if (location.pathname === path) return true;
  // };

  const isActiveLinkStyles = (location, pathArr) => {
    //find alternative solution for nested routes (submenues)
    return pathArr.some((path) => location.pathname.startsWith(path));
  };

  const handleClick = (e, itemPath) => {
    e.stopPropagation();
    // Check if the path is an external link
    if (itemPath.startsWith("http")) {
      // Open in a new tab for external links
      window.open(itemPath, "_blank");
    } else {
      // Use navigate for internal links
      navigate(itemPath, { state: { previousLocationPathname: location.pathname } });
    }
  };

  const handlePopoverOpen = (event, children) => {
    anchorRef.current = event.currentTarget;
    setCurrentChildren(children);
  };

  const handlePopoverClose = () => {
    anchorRef.current = null;
    setCurrentChildren([]);
  };

  const open = Boolean(anchorRef.current);

  if (!matches) {
    if (sideBar !== false) {
      dispatch(setSideBarIsOpen(false));
      setSideBar(false);
    }
    return (
      <Grid container id="sidebar" sx={wrapperMenuItemTop}>
        {SidebarData.filter(
          (el) =>
            !el.isDisabled &&
            ("shouldBeVisibleFor" in el === false || el.shouldBeVisibleFor.includes(jwt.data?.user?.acctId)),
        ).map((i, ind) => {
          return (
            <React.Fragment key={ind + "_" + i.title}>
              <Grid
                item
                sx={
                  isActiveLinkStyles(location, i.pathArr)
                    ? {
                        ...menuItemCollapsed(true),
                        ...menuItemActiveCollapsed(true),
                      }
                    : menuItemCollapsed
                }
                onClick={(e) => {
                  handleClick(e, i.path);
                }}
                onMouseEnter={(e) => !!i.children && handlePopoverOpen(e, i.children)}
              >
                <span>{i.icon}</span>
              </Grid>
              <Popover
                open={open}
                anchorEl={anchorRef.current}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disableRestoreFocus
              >
                <Box sx={innerMenuItemTop} onMouseLeave={handlePopoverClose}>
                  {currentChildren &&
                    currentChildren.map((child, index) => {
                      if (!child.isDisabled) {
                        return (
                          <Box
                            key={index + "_" + child.title}
                            sx={
                              isActiveLinkStyles(location, child.pathArr)
                                ? { color: "#fff" }
                                : { color: "rgba(255,255,255,0.5)" }
                            }
                            onClick={(e) => {
                              handleClick(e, child.path);
                              handlePopoverClose();
                            }}
                          >
                            <Typography>{child.title}</Typography>
                          </Box>
                        );
                      }
                      return null;
                    })}
                </Box>
              </Popover>
            </React.Fragment>
          );
        })}
      </Grid>
    );
  }

  if (!auth.isAuthenticated()) return <Loader />;

  return (
    <Box
      id="sidebar"
      sx={{
        ...sideBarContainer(sideBar),
        height: "calc(100% - 2rem)",
        position: "fixed",
        top: "0px",
        left: "0px",
        margin: "1rem 1.25rem 1rem 1.25rem",
        backgroundColor: "secondary.main",
        borderRadius: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // overflowY: "auto", // these properties remove a sideBarExpandButton shadow
        // overflowX: "hidden",
      }}
      onClick={showSideBar}
    >
      <Box>
        <Box sx={{ position: "relative" }}>
          <Box sx={logoContainer}>
            {/* Logo */}
            {sideBar ? <img src={logo} alt="logo" /> : null}
          </Box>
          <Box sx={sideBarExpandButton(sideBar)} onClick={showSideBar}>
            <ExpandLess id="sidebar-toggler-btn" />
          </Box>
        </Box>
        {sideBar ? (
          <Box>
            {SidebarData.filter(
              (el) =>
                !el.isDisabled &&
                ("shouldBeVisibleFor" in el === false || el.shouldBeVisibleFor.includes(jwt.data?.user?.acctId)),
            ).map((i, ind) => {
              let sideBarItemId = "test_id_sidebar_btn_" + ind + "_" + i.title.replace(/ /g, "-").toLowerCase();
              return (
                <Box key={ind + "_" + i.title}>
                  <Box
                    id={sideBarItemId}
                    sx={
                      // isActive(location, i.path)
                      isActiveLinkStyles(location, i.pathArr)
                        ? { ...menuItem, ...menuItemActive, cursor: "pointer" }
                        : { ...menuItem, cursor: "pointer" }
                    }
                    // disabled={i.isDisabled}
                    onClick={(e) => {
                      handleClick(e, i.path);
                    }}
                  >
                    <Box style={iconSx(sideBar)}>{i.icon}</Box>
                    <Box>{i.title}</Box>
                  </Box>
                  {i.children &&
                    i.children.map((child, index) => {
                      if (!child.isDisabled) {
                        return (
                          <Box
                            key={index + "_" + child.title}
                            sx={
                              isActiveLinkStyles(location, child.pathArr)
                                ? {
                                    ...menuItem,
                                    ...menuItemActive,
                                    height: "2.375rem",
                                    cursor: "pointer",
                                    borderLeft: "none",
                                    alignItems: "start",
                                    justifyContent: "flex-start",
                                  }
                                : {
                                    ...menuItem,
                                    cursor: "pointer",
                                    height: "2.375rem",
                                    borderLeft: "none",
                                    alignItems: "start",
                                    justifyContent: "flex-start",
                                  }
                            }
                            onClick={(e) => {
                              handleClick(e, child.path);
                            }}
                          >
                            <Typography>{child.icon}</Typography>
                            <Typography
                              sx={{
                                lineHeight: "1.25rem",
                                fontSize: "1.25rem",
                                textIndent: "0.3125rem",
                                textAlign: "center",
                              }}
                            >
                              {child.title}
                            </Typography>
                          </Box>
                        );
                      }
                    })}
                </Box>
              );
            })}
            {auth.isAdminRole(jwt) && (
              // auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.PERFORMANCE_REPORT.modules) &&
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  height: "25px",
                }}
              >
                <Typography variant="unselected" sx={{ color: "white" }}>
                  Hide business details
                </Typography>
                <Switch
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                  checked={isPresentationModeEnabled}
                  onChange={(e) => {
                    dispatch(setPresentationMode(!isPresentationModeEnabled));
                  }}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            {SidebarData.filter(
              (el) =>
                !el.isDisabled &&
                ("shouldBeVisibleFor" in el === false || el.shouldBeVisibleFor.includes(jwt.data?.user?.acctId)),
            ).map((i, ind) => {
              let sideBarItemId =
                "test_id_collapsed_sidebar_btn_" + ind + "_" + i.title.replace(/ /g, "-").toLowerCase();
              return (
                <Box
                  key={ind + "_" + i.title}
                  id={sideBarItemId}
                  sx={
                    // isActive(location, i.path)
                    isActiveLinkStyles(location, i.pathArr)
                      ? { ...menuItemCollapsed(), ...menuItemActiveCollapsed() }
                      : menuItemCollapsed()
                  }
                  // disabled={i.isDisabled}
                  onClick={(e) => {
                    handleClick(e, i.path);
                  }}
                >
                  <span>{i.icon}</span>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <Box sx={logoContainer}>
        {sideBar && (
          <Button
            variant="feedback"
            onClick={(e) => {
              e.stopPropagation();
              setFeedbackOpen(true);
            }}
          >
            Need help?
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default Sidebar;
