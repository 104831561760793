import React from "react";
import { Box, FormControl, FormLabel, FormControlLabel, Checkbox } from "@mui/material";
import { CONSTANT } from "../../constants/constants";
import { colors } from "../../theme";

interface ModulePermissionsCheckboxesProps {
  modulesTypes: { [key: string]: [number, number, string] };
  value: { [key: string]: number | null };
  handleChangeCheckbox: (moduleKey: string, permissionValue: number) => void;
  colors: { lightBlue: string };
}

const mockPermission = -99;

export const modulesTypesArray: [string, number, number, string][] = [
  ["rmp", CONSTANT.MODULE.RMP_ADMIN, CONSTANT.MODULE.RMP_VIEW, "Roles management"],
  ["dashboard", CONSTANT.MODULE.DASHBOARD_ADMIN, CONSTANT.MODULE.DASHBOARD_VIEW, "Dashboard"],
  ["billing", CONSTANT.MODULE.BILLING_ADMIN, CONSTANT.MODULE.BILLING_VIEW, "Billing"],
];

export const ModulePermissionsCheckboxes: React.FC<ModulePermissionsCheckboxesProps> = ({
  value,
  handleChangeCheckbox,
}) => {
  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
      {modulesTypesArray.map(([moduleKey, adminPermission, viewPermission, label]) => (
        <Box key={moduleKey} sx={{ display: "contents" }}>
          <FormLabel sx={{ lineHeight: "35px", paddingRight: "1rem" }} id={`${moduleKey}-checkbox-group-label`}>
            {label}
          </FormLabel>

          {[adminPermission, viewPermission].map((permissionValue, index) => {
            return (
              <FormControlLabel
                key={`${moduleKey}-${index}`}
                control={
                  <Checkbox
                    checked={value[moduleKey] === permissionValue}
                    onChange={() => handleChangeCheckbox(moduleKey, permissionValue)}
                    name={moduleKey}
                    sx={{
                      "&.Mui-checked": {
                        color: colors.lightBlue,
                      },
                    }}
                  />
                }
                label={permissionValue % 2 === 0 ? "Admin" : "View"}
                sx={{
                  justifySelf: "end",
                }}
              />
            );
          })}
        </Box>
      ))}
    </Box>
  );
};
