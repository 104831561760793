import { SxProps } from "@mui/material";

export interface ISelectableItem {
  id: string | number;
  name: string;
  isSelected: boolean;
  [key: string]: any;
}

export interface ISelectWrapperProps {
  itemName: string;
  itemFieldName: string;
  items: ISelectableItem[];
  setSelectedItems: (items: ISelectableItem[]) => void;
  isSingleSelect: boolean;
  actionItem?: IActionItem;
  isDisabled?: boolean;
  customDropdownStyle?: (isExpanded: boolean, isDisabled?: boolean) => SxProps;
  tagsPlacement?: TagsPlacement;
  withSelectAllCheckBox?: boolean;
}
export enum TagsPlacement {
  TOP = "top",
  BOTTOM = "bottom",
}

export interface ItemListProps {
  itemName: string;
  itemFieldName: string;
  items: ISelectableItem[];
  actionItem?: IActionItem;
  handleToggle: (item: ISelectableItem) => void;
}

export interface IActionItem {
  name: string;
  onClick: () => void;
}
