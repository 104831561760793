import { Box, Divider, Popover, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import NotificationsIcon from "../../components/IconComponents/NotificationsIcon";
import { useGetReceivedNotificationsQuery, useMarkNotificationAsReadMutation } from "./notificationsApi";
import { colors } from "../../theme";
import ReceivedNotificationsList from "./ReceivedNotificationsList";
import { NotificationStatus } from "./noticeTypes";

const ReceivedNotifications = () => {
  const { data: receivedNotifications, isSuccess, isLoading, refetch } = useGetReceivedNotificationsQuery();
  const [markAsRead] = useMarkNotificationAsReadMutation();
  const unreadedNotices =
    receivedNotifications?.filter((notice) => notice.status.id === NotificationStatus.SENT.id) ?? [];

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isHoveringPopover, setIsHoveringPopover] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        refetch();
      },
      5 * 60 * 1000,
    ); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, [refetch]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (isHandlePopoverClose: boolean = false) => {
    timeoutRef.current = setTimeout(() => {
      if (isHandlePopoverClose || !isHoveringPopover) {
        setAnchorEl(null);
      }
    }, 100);
  };
  const handlePopoverMouseEnter = () => {
    setIsHoveringPopover(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  const handlePopoverMouseLeave = () => {
    setIsHoveringPopover(false);
    handlePopoverClose(true);
  };
  //   const open = Boolean(anchorEl);

  const handleClickMarkAllNoticesAsRead = async (event: React.MouseEvent<HTMLSpanElement>): Promise<void> => {
    event.stopPropagation();
    const unreadedNoticesIds = unreadedNotices?.map((notice) => notice.id) ?? [];
    try {
      await markAsRead(unreadedNoticesIds).unwrap();
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        aria-owns={Boolean(anchorEl) ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={refetch}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={() => handlePopoverClose(true)}
      >
        <NotificationsIcon color={unreadedNotices?.length > 0 ? colors.brandDefault : colors.netural200} />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          padding: "0",
          margin: "0",
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              display: "flex",
              width: "384px",
              height: "478px",
              padding: "12px 0px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
              borderRadius: "24px",
              background: colors.mainWhite,
              boxShadow: "0px 4px 4px 0px rgba(16, 29, 65, 0.10), 0px -2px 6px 0px rgba(16, 29, 65, 0.10)",
              pointerEvents: "auto",
            },
            onMouseLeave: handlePopoverMouseLeave,
            onMouseEnter: handlePopoverMouseEnter,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => handlePopoverClose(true)}
        // disableRestoreFocus
      >
        <Box
          sx={{
            display: "flex",
            padding: "0px 24px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            alignSelf: "stretch",
            textAlign: "center",
          }}
        >
          <Typography variant="h3Medium600" sx={{ fontWeight: 700 }}>
            Notifications
          </Typography>
          <Box onClick={handleClickMarkAllNoticesAsRead}>
            {unreadedNotices && unreadedNotices?.length > 0 ? (
              <Typography
                variant="body2XSmall"
                sx={{ fontWeight: 600, lineHeight: "20px", color: colors.brandDefault, cursor: "pointer" }}
              >
                Mark all as read
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Divider
          sx={{
            width: "100%",
            borderColor: colors.borderDefault,
          }}
        />
        {!isLoading && isSuccess && receivedNotifications && receivedNotifications.length > 0 && (
          <ReceivedNotificationsList notifications={receivedNotifications} />
        )}
      </Popover>
    </Box>
  );
};

export default ReceivedNotifications;
