import { useState, useEffect, useRef } from "react";
import { Typography, Box, InputAdornment, IconButton } from "@mui/material";
import SideBoardWrapper from "./sideBoard/SideBoardWrapper";
import RolesManagementMainTable from "./rolesMangementComponents/RolesManagementMainTable";
import { useGetAccountsQuery } from "./accountsApi";
import RoleDetailsPopup from "./rolesMangementComponents/RoleDetailsPopup";
import { getRoleDetails } from "./api/accounts-api";
import { TextField } from "@mui/material";
import LoaderWithProgress from "../components/LoaderWithProgress/LoaderWithProgress";
import AdminElement from "../components/ElementWrappers/AdminElement";
import SessionTimeout from "../components/SessionTimeout/SessionTimeout";
import { pageContainerStyle, textFieldInputStl } from "./styles";
import SearchIcon from "@mui/icons-material/Search";

export default function RolesManagementPage() {
  const {
    data: accounts,
    isFetching,
    isLoading,
    refetch: refetchAccountsAndRoles,
    isError,
    error,
  } = useGetAccountsQuery();
  const [filterText, setFilterText] = useState(""); // State to store the filter value
  const filteredRoles = accounts?.roles.filter(
    (role) =>
      role.name.toLowerCase().includes(filterText.toLowerCase()) ||
      role.accountName.toLowerCase().includes(filterText.toLowerCase()),
  );
  const [open, setOpen] = useState(false);
  const conbinedDataRef = useRef([]);
  const name = useRef("");

  const updateDataAndRows = (data) => {
    refetchAccountsAndRoles();
  };
  const handleRowClick = (params) => {
    getRoleDetails(params.row.id).then((data) => {
      if (data.statusCode === 403) {
        alert("Action stopped: " + data.error);
        throw new Error("Forbidden_resource");
      }

      conbinedDataRef.current = data;
      name.current = params.row.name;
      setOpen(true);
    });
  };
  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  if (isError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "75vh",
        }}
      >
        <Typography variant="h6" color="error">
          No roles to display
        </Typography>
      </Box>
    );
  }
  if (isLoading || isFetching) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <Box sx={pageContainerStyle}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
          width: "100%",
        }}
      >
        <AdminElement canAdmin={accounts?.list ?? false}>
          <Box sx={{ maxWidth: 300 }}>
            <TextField
              id="search"
              type="search"
              placeholder="Search..."
              value={filterText}
              onChange={handleFilterChange}
              // onCancelSearch={() => cancelSearch()}
              sx={{
                width: "256px",
                fieldset: { borderColor: "#E0E2E7", borderRadius: "0.5rem" },
                height: "52px",
                ...textFieldInputStl,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ width: "256px" }}>
            <SideBoardWrapper
              type="role_creation"
              headerTitle="Create new role"
              btnTitle="Add new role"
              updateDataAndRows={updateDataAndRows}
              value={{}}
            />
          </Box>
        </AdminElement>
      </Box>
      <RolesManagementMainTable rolesList={filteredRoles} onHandleRowClick={handleRowClick} />
      {open ? <RoleDetailsPopup setOpen={setOpen} data={conbinedDataRef.current} name={name.current} /> : null}
      <SessionTimeout />
    </Box>
  );
}
