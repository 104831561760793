import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../theme";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "../../components/IconComponents/ExpandMoreIcon";
import CircleIcon from "@mui/icons-material/Circle";
import {
  customAccountsSelectionStyle,
  defaultNoticeTextFieldStyle,
  getAccordionSummaryStyle,
  MAX_TEXT_LENGTH,
  MAX_TITLE_LENGTH,
  CreateNotificationDto,
  NotificationLevel,
  notificationRecipientTypes,
  notificationSteps,
  notificationStepTypes,
  SelectionItem,
  NotificationContext,
  notificationLevelSelection,
  notificationContextSelection,
} from "./noticeTypes";
import NoticeSwitchSelectorWrapper from "./NoticeSwitchSelectorWrapper";
import { useGetAccountsQuery } from "../../accounts/accountsApi";
import { SelectItemsWrapper } from "../../components/SelectItems/SelectItemsWrapper";
import { ISelectableItem, TagsPlacement } from "../../components/SelectItems/interfaces";
import { useCreateNotificationMutation } from "./notificationsApi";
import { message } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CONSTANT } from "../../constants/constants";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";

const CreateNewNotificationForm = () => {
  const { data: accountsData, status: getAccountsStatus } = useGetAccountsQuery();
  const [createNotification, { status: creationStatus, error: creationError }] = useCreateNotificationMutation();
  const { loggedUser } = useSelector((state: RootState) => state.users);
  const [notificationLevel, setNotificationLevel] = useState<SelectionItem>(NotificationLevel.INIT);
  const [notificationContext, setNotificationContext] = useState<SelectionItem>(NotificationContext.SYSTEM);
  const [recipientType, setRecipientType] = useState<SelectionItem>(notificationRecipientTypes.specific);
  const [expandedSteps, setExpandedSteps] = useState<{ [key: number]: boolean }>({});
  const [title, setTitle] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [accounts, setAccounts] = useState<ISelectableItem[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<ISelectableItem[]>([]);

  useEffect(() => {
    if (accountsData?.list?.length > 0) {
      let accountsList = accountsData?.list
        .filter(
          (acc: any) => acc.status && acc.status !== CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.value && acc.canAdmin,
        )
        .map((acc: any) => {
          return {
            ...acc,
            isSelected: false,
          };
        });
      setAccounts(accountsList);
    }
  }, [accountsData]);

  useEffect(() => {
    if (creationStatus === QueryStatus.fulfilled) {
      message.success(`The notification was sent successfully.`);
    } else if (creationStatus === QueryStatus.rejected) {
      console.error("Failed to create notification:", creationError);
      message.error(`Error sending notification.`);
    }
  }, [creationStatus, creationError]);

  const handleExpandStep = (stepId: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedSteps((prevExpandedSteps) => ({
      ...prevExpandedSteps,
      [stepId]: isExpanded,
    }));
  };

  const handleChangeNotificationLevel = (event: SelectChangeEvent<number>) => {
    const labelId = event.target.value as number;
    const selectedNotificationLevel = notificationLevelSelection.find((level) => level.id === labelId);
    if (selectedNotificationLevel) {
      setNotificationLevel(selectedNotificationLevel);
    }
  };

  const handleChangeNotificationType = (type: SelectionItem) => {
    setNotificationContext(type);
  };

  const handleChangeRecipientType = (type: SelectionItem) => {
    setRecipientType(type);
  };

  const handleSetSelectedAccounts = (newSelectedItems: ISelectableItem[]) => {
    setAccounts(newSelectedItems);
    setSelectedAccounts(newSelectedItems.filter((account) => account.isSelected));
  };

  const handleClickCreateNotice = async () => {
    const recipientIds = selectedAccounts
      .flatMap((acc) => acc.users.map((user: any) => user.id))
      .filter((id: number) => id !== loggedUser?.id);
    try {
      const notification: CreateNotificationDto = {
        title,
        text,
        context: notificationContext,
        level: notificationLevel,
        // status: NotificationStatus.DRAFT,
        recipientIds,
      };

      await createNotification(notification);
    } catch (error) {
      console.error("Failed to create notification:", error);
      message.error(`Error sending notification.`);
    }
  };

  const isCanCreate =
    title.length > 0 &&
    text.length > 0 &&
    selectedAccounts.length > 0 &&
    notificationLevel.id !== NotificationLevel.INIT.id;

  return (
    <Box sx={{ marginTop: "40px", width: "776px" }}>
      {accounts.length === 0 && getAccountsStatus === QueryStatus.fulfilled ? (
        <Typography variant="titleSmall">
          <strong>
            You don't have any applicable recipients to send notifications to. Please contact administrator.
          </strong>
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            border: `1px solid ${colors.borderDefault}`,
            borderRadius: "24px",
            padding: "16px 24px",
            background: colors.mainWhite,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignSelf: "stretch",
              width: "100%",
            }}
          >
            <Typography variant="titleSmall" align="left">
              Create new notification
            </Typography>
            <Select
              labelId="notification-level"
              id="notification-select-level"
              value={notificationLevel.id}
              renderValue={(selectedId) => {
                const selectedLabel = notificationLevelSelection.find((label) => label.id === selectedId);
                return selectedLabel ? selectedLabel.title : "";
              }}
              label=""
              onChange={handleChangeNotificationLevel}
              sx={{
                border: "none",
                borderRadius: "16px",
                background: `color-mix(in srgb, ${notificationLevel.color}, transparent 70%)`,
                display: "flex",
                width: "169px",
                padding: "4px 12px 4px 16px",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 500,
                gap: "8px",
                "& .MuiSelect-select": {
                  paddingRight: "32px",
                  border: "none",
                },
                "& .MuiOutlinedInput-input": {
                  padding: 0,
                  border: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              MenuProps={{
                slotProps: {
                  paper: {
                    sx: {
                      marginTop: "8px",
                      border: `1px solid ${colors.borderDefault}`,
                      borderRadius: "16px",
                      boxShadow: "0 40px 40px 0px rgba(16, 29, 65, 0.10)",
                      background: `${colors.mainWhite}`,
                    },
                  },
                },
                MenuListProps: {
                  sx: {
                    padding: 0,
                  },
                },
              }}
            >
              {notificationLevelSelection.map(
                (noticeLevel) =>
                  noticeLevel.id !== NotificationLevel.INIT.id && (
                    <MenuItem
                      key={noticeLevel.id}
                      value={noticeLevel.id}
                      sx={
                        {
                          // // background: `color-mix(in srgb, ${noticeLevel.color}, transparent 70%)`,
                          // "&.Mui-selected": {
                          //   // Change this line
                          //   backgroundColor: `#E6E8E9`, // Or any color you prefer
                          // },
                          // "&.MuiMenuItem-root:hover": {
                          //   // Add this to style the hover state of selected item
                          //   backgroundColor: `color-mix(in srgb, ${noticeLevel.color}, transparent 70%)`, // Or any color you prefer
                          // },
                          // display: "flex",
                          // flexDirection: "row",
                          // padding: "8px",
                          // alignItems: "center",
                          // justifyContent: "space-between",
                          // gap: "12px",
                          // alignSelf: "stretch",
                          // borderRadius: "10px",
                        }
                      }
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          padding: "8px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "12px",
                          borderRadius: "10px",
                          margin: "0",
                          // background: `${colors.blue300}`,
                        }}
                      >
                        <Typography variant="body1Small400"> {noticeLevel.title}</Typography>
                        <CircleIcon sx={{ width: "18px", height: "18px", color: `${noticeLevel.color}` }} />
                      </Box>
                    </MenuItem>
                  ),
              )}
            </Select>
          </Box>
          <Divider
            sx={{
              width: "calc(100% + 48px)", // 24px padding on each side, because of padding parent box
              borderColor: colors.borderDefault,
            }}
          />
          <NoticeSwitchSelectorWrapper
            types={notificationContextSelection}
            selectedType={notificationContext}
            handleChangeType={handleChangeNotificationType}
          />
          <Divider
            sx={{
              width: "calc(100% + 48px)", // 24px padding on each side, because of padding parent box
              borderColor: colors.borderDefault,
            }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "8px",
            }}
          >
            {notificationSteps.map((step) => (
              <Accordion
                key={step.id}
                expanded={expandedSteps[step.id] || false}
                onChange={handleExpandStep(step.id)}
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  "&.Mui-expanded": {
                    margin: 0, // Set margin to 0 for expanded state
                  },
                }}
              >
                <AccordionSummary
                  sx={getAccordionSummaryStyle(expandedSteps[step.id] || false)}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${step.description}-notification-content`}
                  id={`${step.description}-notification-content-header`}
                >
                  <Typography variant="h3Medium600" fontWeight={700} color={colors.brandPressed}>
                    {step.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {step.id === notificationStepTypes.common.id && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        margin: "24px 0 0 0",
                        gap: "16px",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Typography variant="body2XSmallMain400">
                          Title <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          sx={defaultNoticeTextFieldStyle}
                          placeholder="Type here"
                          fullWidth
                          required
                          inputProps={{ maxLength: MAX_TITLE_LENGTH }}
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                        />
                        <Typography variant="bodySmallRegular">{`Characters left: ${MAX_TITLE_LENGTH - title.length}`}</Typography>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Typography variant="body2XSmallMain400">
                          Text <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField
                          sx={{
                            ...defaultNoticeTextFieldStyle,
                            "& .MuiOutlinedInput-root": {
                              ...(defaultNoticeTextFieldStyle as Record<string, any>)["& .MuiOutlinedInput-root"],
                              padding: "0",
                            },
                          }}
                          placeholder="Type here"
                          fullWidth
                          required
                          multiline
                          rows={3}
                          inputProps={{ maxLength: MAX_TEXT_LENGTH }}
                          onChange={(e) => setText(e.target.value)}
                          value={text}
                        />
                        <Typography variant="bodySmallRegular">{`Characters left: ${MAX_TEXT_LENGTH - text.length}`}</Typography>
                      </Box>
                    </Box>
                  )}
                  {step.id === notificationStepTypes.users.id && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        margin: "24px 0 0 0",
                        gap: "16px",
                      }}
                    >
                      <Typography variant="titleSmall">Recipients</Typography>
                      {/* <NoticeSwitchSelectorWrapper
                      types={notificationRecipientTypesSelection}
                      selectedType={recipientType}
                      handleChangeType={handleChangeRecipientType}
                    /> */}

                      {recipientType.id === notificationRecipientTypes.specific.id && accounts.length > 0 && (
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              margin: " 6px 0",
                            }}
                          >
                            <Typography variant="body2XSmallMain400">
                              Accounts <span style={{ color: "red" }}>*</span>
                            </Typography>
                          </Box>
                          <SelectItemsWrapper
                            itemName="account"
                            itemFieldName="company"
                            items={accounts}
                            setSelectedItems={handleSetSelectedAccounts}
                            isSingleSelect={false}
                            customDropdownStyle={(isExpanded: boolean) => customAccountsSelectionStyle(isExpanded)}
                            tagsPlacement={TagsPlacement.BOTTOM}
                            withSelectAllCheckBox={true}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Button
            variant="apply"
            size="customMedium"
            sx={{ alignSelf: "end", marginTop: "16px" }}
            onClick={handleClickCreateNotice}
            disabled={!isCanCreate}
          >
            Create notification
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CreateNewNotificationForm;
