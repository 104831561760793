import React, { useState, useEffect } from "react";
import { Box, ButtonBase, Popper, TextField, InputAdornment } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ISelectWrapperProps, ISelectableItem, TagsPlacement } from "./interfaces";
import ItemList from "./ItemsList";
import { SelectedItemButton } from "../Button/SelectedItemButton";
import { colors } from "../../theme";
import CheckboxWrapper from "../CustomCheckbox/Checkbox";

export const SelectItemsWrapper: React.FC<ISelectWrapperProps> = ({
  items,
  setSelectedItems,
  itemName,
  itemFieldName,
  isSingleSelect = false,
  actionItem,
  isDisabled = false,
  customDropdownStyle,
  tagsPlacement = TagsPlacement.TOP,
  withSelectAllCheckBox = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState(`Select ${itemName}(s)`);
  const [borderColor, setBorderColor] = useState("#E0E2E7");
  const [filterText, setFilterText] = useState("");
  const [checkAll, setCheckAll] = useState<Boolean>(false);

  const defaultTextFiledStyle = {
    borderRadius: "8px",
    border: `1px solid ${open ? colors.lightBlue : "#E3E3E3"}`,
    "& .MuiInputBase-root": {
      "& > fieldset": {
        border: "none", /// `${open ? "none" : "1px solid #E3E3E3"}`,
      },
    },
    // "& .MuiInputBase-root.Mui-focused": {
    //   "& > fieldset": {
    //     border: `1px solid ${colors.lightBlue}`,
    //   },
    // },
    input: {
      paddingLeft: "15px",
      color: isDisabled ? "grey" : "secondary.mainDark", // Change the color when disabled
      fontSize: "1rem",
      "&::placeholder": {
        opacity: 1,
      },
    },
  };
  const textFieldStyle = customDropdownStyle ? customDropdownStyle(open) : defaultTextFiledStyle;
  useEffect(() => {
    setBorderColor(!open ? "#E0E2E7" : "#1BB2FF");
    const selectedCount = items?.filter((item) => item.isSelected).length;
    setLabel(selectedCount > 0 ? `${selectedCount} ${itemName}(s) selected` : `Select ${itemName}(s)`);
    if (items?.length > 0) {
      setCheckAll(items.length > 0 && selectedCount === items.length);
    }
  }, [open, items]);

  const handleToggle = (itemToToggle: ISelectableItem) => {
    let newItems;
    if (isSingleSelect) {
      newItems = items.map((item) =>
        item.id === itemToToggle.id ? { ...item, isSelected: true } : { ...item, isSelected: false },
      );
      setFilterText("");
      setOpen(false);
    } else {
      newItems = items.map((item) =>
        item.id === itemToToggle.id ? { ...item, isSelected: !item.isSelected } : item,
      );
    }

    const selectedItems = newItems.filter((item) => item.isSelected);

    setCheckAll(items.length > 0 && selectedItems.length === items.length);
    setSelectedItems(newItems);
  };

  const handleSelectAll = (checked: boolean) => {
    let newItems = items.map((item) => {
      return { ...item, isSelected: checked };
    });
    setFilterText("");
    setCheckAll(checked);
    setSelectedItems(newItems);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDisabled) {
      setAnchorEl(open ? null : event.currentTarget);
      setOpen(!open);
    }
  };
  const filteredItems = items?.filter((item) =>
    item[itemFieldName]?.toLowerCase().includes(filterText.toLowerCase()),
  );

  const onDelete = (itemToToggle: any) => {
    setSelectedItems(
      items.map((item) => {
        return { ...item, isSelected: item.id === itemToToggle.id ? false : item.isSelected };
      }),
    );
    setCheckAll(false);
  };
  const selectedItem = items?.find((i) => i.isSelected);
  const selectedItemName = selectedItem ? selectedItem[itemFieldName] : `Select ${itemName}`;

  if (isSingleSelect && (!items?.length || items?.length === 0)) return <></>;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "6px", width: "100%" }}>
      {!isSingleSelect && items?.length > 0 && tagsPlacement === TagsPlacement.TOP && (
        <Box sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}>
          {items
            .filter((i) => i.isSelected)
            .map((a, index) => {
              return <SelectedItemButton key={a.id} item={a} onDelete={onDelete} itemFieldName={itemFieldName} />;
            })}
        </Box>
      )}
      <ButtonBase onClick={handleClick} sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TextField
            disabled={isDisabled}
            variant="outlined"
            fullWidth
            placeholder={!isSingleSelect ? `Search ${itemName}s` : selectedItemName}
            onChange={(e) => setFilterText(e.target.value)}
            value={filterText}
            autoComplete="off"
            sx={textFieldStyle}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <KeyboardArrowDownIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{ zIndex: "2010", backgroundColor: "white", borderRadius: "16px" }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: ({ placement, reference, popper }: any) => {
                return [0, 10];
              },
            },
          },
          {
            name: "computeStyles",
            options: {
              gpuAcceleration: false,
            },
          },
          {
            name: "matchWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`; // Set popper width to match reference
            },
            effect: ({ state }) => {
              if (state.elements.reference instanceof HTMLElement) {
                state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`; // Initial width adjustment
              }
              return () => {};
            },
          },
          { name: "flip", options: { padding: 1 } },
        ]}
      >
        <ClickAwayListener
          onClickAway={() => {
            setFilterText("");
            setOpen(false);
          }}
        >
          <Box sx={{ backgroundColor: "transparent" }}>
            {!isSingleSelect && withSelectAllCheckBox && (
              <Box
                onClick={() => handleSelectAll(!checkAll)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "12px",
                  padding: "10px 0 0 10px",
                }}
              >
                <CheckboxWrapper
                  checked={checkAll}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectAll(e.target.checked)}
                  id="check-all"
                  onMouseDown={(e: React.MouseEvent) => e.preventDefault()}
                />
                Select All
              </Box>
            )}
            <ItemList
              itemName={itemName}
              items={filteredItems}
              itemFieldName={itemFieldName}
              handleToggle={handleToggle}
              actionItem={actionItem}
            />
          </Box>
        </ClickAwayListener>
      </Popper>
      {!isSingleSelect && items?.length > 0 && tagsPlacement === TagsPlacement.BOTTOM && (
        <Box sx={{ display: "flex", gap: "6px", flexWrap: "wrap", maxHeight:"18rem", overflow:"auto" }}>
          {items
            .filter((i) => i.isSelected)
            .map((a, index) => {
              return <SelectedItemButton key={a.id} item={a} onDelete={onDelete} itemFieldName={itemFieldName} />;
            })}
        </Box>
      )}
    </Box>
  );
};
