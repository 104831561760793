import * as React from "react";
import { styled } from "@mui/material/styles";
import { CONSTANT } from "../../../src/constants/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditOutlinedIcon from "@mui/icons-material/ModeEdit";
import CloseIcon from "@mui/icons-material/Close";
import { Box, TextField, Button, Typography } from "@mui/material";
import { BasicTooltip, TooltipMultiselectGroup } from "../BasicTooltip/BasicTooltip";

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "1rem 2rem",
  textTransform: "none",
  fontSize: "1.12rem",
  fontWeight: 700,
  lineHeight: "1.37rem",
}));

export default function BrandButton({ children, ...props }) {
  return <StyledButton {...props}> {children} </StyledButton>;
}

export function MultiselectGroupButton({ name, isActive, onClick, onDelete, groupId, onEdit, partnersString }) {
  const displayIcons = isActive && groupId !== CONSTANT.allGroupId;
  return (
    <Box sx={{ display: "flex", gap: "2px" }}>
      <Button
        onClick={onClick}
        sx={{
          color: isActive ? "white" : "#001738",
          borderRadius: "12px 2px 2px 12px",
          backgroundColor: isActive ? "#233450" : "#EFF0F2",
          height: "32px",
          fontSize: "12px",
          width: "fit-content",
          ...(isActive && {
            "&:hover": {
              backgroundColor: "#233450", // Same as default background color
              color: "white", // Same as default color
              // Repeat any other styles that should remain the same on hover
            },
          }),
        }}
      >
        <BasicTooltip tooltipText={partnersString}>
          <Box sx={{ display: "flex", gap: "3px" }}>
            {!displayIcons && <Box sx={{ width: "16px" }} />}
            <Typography>{name}</Typography>
            {!displayIcons && <Box sx={{ width: "16px" }} />}
          </Box>
        </BasicTooltip>
      </Button>
      <Button
        onClick={onClick}
        sx={{
          color: isActive ? "white" : "#001738",
          borderRadius: "2px 12px 12px 2px",
          backgroundColor: isActive ? "#233450" : "#EFF0F2",
          height: "32px",
          minWidth: "48px",
          ...(isActive && {
            "&:hover": {
              backgroundColor: "#233450", // Same as default background color
              color: "white", // Same as default color
              // Repeat any other styles that should remain the same on hover
            },
          }),
        }}
      >
        <EditOutlinedIcon
          sx={{
            fontSize: "1.4rem",
            marginRight: "4px",
            "&:hover": {
              // Styles for hover state of EditOutlinedIcon
              color: "wheat", // Example: change color on hover
              cursor: "pointer",
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (onEdit) {
              onEdit();
            }
          }}
        ></EditOutlinedIcon>
      </Button>
    </Box>
  );
}

export function BackButton({ children, ...props }) {
  return (
    <StyledButton {...props}>
      <ArrowBackIcon sx={{ marginRight: "0.875rem" }} />
      Back
    </StyledButton>
  );
}
