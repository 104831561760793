import React, { useEffect, useState } from "react";
import { CategoriesType, FilterGroup } from "../../../../parametersConstancy/parametersConstancyTypes";
import { Box, Button, Divider, Step, Stepper, TextField, Typography } from "@mui/material";
import { colors } from "../../../../theme";
import EmptyDataIcon from "../../../../components/IconComponents/EmptyDataIcon";
import GroupFilterHandler from "./GroupFilterHandler";
import ConfirmationDialog from "../../../../components/Dialogs/ConfirmationDialog";
import { DialogTypes } from "./FilterGroupDialogForm";
import { mappingFiltersToFilterGroup } from "../../../../parametersConstancy/parametersConstancyUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { CONSTANT } from "../../../../constants/constants";
import { useGetFilterGroupsQuery } from "../../../../parametersConstancy/parametersContancyFetches";

interface FilterGroupDialogStepperProps {
  dialogActionsType: string;
  filterGroup?: FilterGroup | null;
  options: CategoriesType;
  values: CategoriesType;
  isOnlyOnePartner: boolean;
  partnersOptions: CategoriesType;
  partnersValues: CategoriesType;
  handleClickDeleteGroup: (filterGroup: FilterGroup | null) => void;
  handleClickCreateGroup: (filterGroup: FilterGroup) => void;
  handleClickUpdateGroup: (filterGroup: FilterGroup) => void;
  enableFilters?: boolean;
  type: string;
}

const STEPS_ENUM = {
  GROUP_NAME: { id: 0, name: "Group name" },
  PARTNERS: { id: 1, name: "Partners" },
  FILTERS: { id: 2, name: "Filters" },
};

const MAX_GROUP_NAME_LENGTH = 40;
const FilterGroupDialogStepper: React.FC<FilterGroupDialogStepperProps> = ({
  dialogActionsType = DialogTypes.CREATE,
  filterGroup = null,
  options,
  values,
  isOnlyOnePartner,
  partnersOptions,
  partnersValues,
  handleClickDeleteGroup,
  handleClickCreateGroup,
  handleClickUpdateGroup,
  enableFilters = true,
  type,
}) => {
  const { data: filterGroupsRaw } = useGetFilterGroupsQuery();

  const currentUserAccountId = useSelector((state: RootState) => state.users.loggedUser?.account_id);
  const steps = Object.values(STEPS_ENUM)
    .filter(
      (step) => !isOnlyOnePartner || currentUserAccountId === CONSTANT.IIQ_ || STEPS_ENUM.PARTNERS.id !== step.id,
    )
    .filter((step) => step.id !== STEPS_ENUM.FILTERS.id || enableFilters);

  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [groupName, setGroupName] = useState<string>(filterGroup?.name ?? "");
  const [nextDisabled, setNextDisabled] = useState(false);
  const [nameDuplicated, setNameDuplicated] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [filtersValues, setFiltersValues] = useState<CategoriesType>(values);
  const [filtersOptions, setFiltersOptions] = useState<CategoriesType>(options);
  const [partnersUpdatedValues, setPartnersUpdatedValues] = useState<CategoriesType>(partnersValues);
  const [partnersUpdatedOptions, setPartnersUpdatedOptions] = useState<CategoriesType>(partnersOptions);

  const finalStepActionButtonTitle = dialogActionsType === DialogTypes.CREATE ? "Create group" : "Save changes";

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStepIndex === totalSteps() - 1;
  };

  useEffect(() => {
    setNameDuplicated(
      filterGroupsRaw?.find((g) => g.name === groupName) !== undefined && dialogActionsType === DialogTypes.CREATE,
    );
    setNextDisabled(
      groupName?.length === 0 ||
        (nameDuplicated && dialogActionsType === DialogTypes.CREATE) ||
        (type === CONSTANT.FILTER_GROUP_TYPE.PARTNER &&
          partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base]?.length === 0),
    );
  }, [groupName, nameDuplicated, partnersUpdatedValues]);

  const handleStepAction = () => {
    if (isLastStep()) {
      if (dialogActionsType === DialogTypes.CREATE) {
        const createdFilterGroup: FilterGroup = mappingFiltersToFilterGroup(
          groupName,
          enableFilters ? values : {},
          enableFilters ? options : {},
          partnersUpdatedValues,
          type,
        );
        handleClickCreateGroup(createdFilterGroup);
      } else {
        const updatedFilterGroup: FilterGroup = mappingFiltersToFilterGroup(
          groupName,
          enableFilters ? filtersValues : {},
          enableFilters ? filtersOptions : {},
          partnersUpdatedValues,
          type,
          filterGroup?.id,
        );
        handleClickUpdateGroup(updatedFilterGroup);
      }
    } else {
      const newActiveStep = activeStepIndex + 1;
      setActiveStepIndex(newActiveStep);
    }
  };

  const handleStep = (stepIndex: number) => () => {
    if (groupName?.length <= 0) {
      return;
    }
    setActiveStepIndex(stepIndex);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        height: "532px",

        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "0 24px",
        }}
      >
        <Stepper
          nonLinear
          alternativeLabel
          activeStep={activeStepIndex}
          connector={null}
          sx={{ display: "flex", flexDirection: "row", gap: "12px", marginBottom: "24px" }}
        >
          {steps.map((step, index) => (
            <Step
              disabled={nextDisabled}
              key={step.id}
              sx={{
                borderBottom:
                  step.id === steps[activeStepIndex].id
                    ? `2px solid ${colors.text01}`
                    : `2px solid ${colors.textBaseTertiary}`,
                padding: "8px 0 0 0",
              }}
              onClick={handleStep(index)}
            >
              <Button variant="clearAll">
                <Typography
                  variant="bodyLargeRegular"
                  sx={{
                    fontWeight: step.id === steps[activeStepIndex].id ? 600 : 500,
                    color:
                      step.id === steps[activeStepIndex].id ? `${colors.text01}` : `${colors.textBaseTertiary}`,
                  }}
                >
                  {step.name}
                </Typography>
              </Button>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ width: "100%" }}>
          <Box>
            {steps[activeStepIndex].id === STEPS_ENUM.GROUP_NAME.id && (
              <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                <TextField
                  onChange={(e) => setGroupName(e.target.value)}
                  value={groupName}
                  inputProps={{ maxLength: MAX_GROUP_NAME_LENGTH }}
                  fullWidth
                  required
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "48px",
                      borderRadius: "8px",
                      "& > fieldset": {
                        " > legend": {
                          fontSize: "0.85em",
                          "@media (min-width: 1600px)": {
                            fontSize: "0.7em",
                          },
                        },
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: `1px`,
                      borderColor: `${colors.borderPressed}`,
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: `${colors.borderPressed}`,
                    },
                  }}
                  placeholder="Type here"
                />
                {nameDuplicated && (
                  <Typography
                    color={"red"}
                    variant="bodySmallRegular"
                  >{`Group with this name already exists, please enter different name`}</Typography>
                )}
                <Typography variant="bodySmallRegular">{`Number of characters: ${MAX_GROUP_NAME_LENGTH - groupName.length}`}</Typography>
                <Box sx={{ alignSelf: "center", marginTop: "34px" }}>
                  <EmptyDataIcon />
                </Box>
              </Box>
            )}
            {steps[activeStepIndex].id === STEPS_ENUM.PARTNERS.id && (
              <Box>
                <GroupFilterHandler
                  options={partnersUpdatedOptions}
                  values={partnersUpdatedValues}
                  setFiltersOptions={setPartnersUpdatedOptions}
                  setFiltersValues={setPartnersUpdatedValues}
                  isReadOnlyView={dialogActionsType === DialogTypes.CREATE}
                  withPartners={true}
                />
              </Box>
            )}
            {steps[activeStepIndex].id === STEPS_ENUM.FILTERS.id && (
              <Box>
                <GroupFilterHandler
                  options={filtersOptions}
                  values={filtersValues}
                  setFiltersOptions={setFiltersOptions}
                  setFiltersValues={setFiltersValues}
                  isReadOnlyView={dialogActionsType === DialogTypes.CREATE}
                  withPartners={false}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Divider variant="fullWidth" />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",

            padding: "16px 24px 0 12px",
          }}
        >
          {dialogActionsType === DialogTypes.EDIT ? (
            <Button
              variant="clearAll"
              size="customMedium"
              sx={{
                textAlign: "left",
                color: `${colors.statusErrorDefault}`,
                ":hover": {
                  background: colors.mainWhite,
                },
              }}
              onClick={handleOpenConfirmationDialog}
            >
              Delete group
            </Button>
          ) : (
            <Box />
          )}
          <Button disabled={nextDisabled} variant="apply" size="customMedium" onClick={handleStepAction}>
            <Typography
              variant="body1Small600"
              sx={{ color: `${nextDisabled ? colors.textDisabled : colors.mainWhite}` }}
            >
              {activeStepIndex === STEPS_ENUM.FILTERS.id ||
              (activeStepIndex === STEPS_ENUM.PARTNERS.id && !enableFilters)
                ? finalStepActionButtonTitle
                : "Next step"}
            </Typography>
          </Button>
        </Box>
      </Box>
      <ConfirmationDialog
        isOpen={openConfirmationDialog}
        title={"Delete group"}
        body={"Are you sure about deleting this group?"}
        confirmButtonTitle={"Yes, delete group"}
        declineButtonTitle={""}
        onConfirm={() => {
          handleCloseConfirmationDialog();
          handleClickDeleteGroup(filterGroup);
        }}
        onDecline={handleCloseConfirmationDialog}
      />
    </Box>
  );
};

export default FilterGroupDialogStepper;
