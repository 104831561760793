import { PaletteOptions, createTheme } from "@mui/material/styles";
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

export const colors = {
  secGreenDark: "#0B8744",
  secGreen100: "#0CD467",
  secGreen5: "#EDFEF5",
  secRed100: "#FE1616",
  secRed10: "#FEE0E2",
  secPp40: "#EFA5FF",
  mainDarkPrimary100: "#001738",
  mainDarkPrimary10: "#E0E2E7",
  mainDarkPrimary40: "#8590A0",
  lightGrey: "#FAFAFA",
  statusWarningBackgroundSubtle: "#FFF6EC",
  statusWarningHover: "#E8983A",
  lightBlue: "#1BB3FF",
  primaryCta5: "#F0FAFF",
  primaryCta10: "#E0F7FF",
  primaryCta40: "#84DAFF",
  primaryCta60: "#47C9FF",
  primaryCta100: "#1BB2FF",
  primaryDark5: "#EFF0F2",
  primaryDark100: "#001738",
  buttonHover: "#0573C5",
  //new design colors
  text01: "#101D41",
  text02: "#647091",
  text05: "#F1F5FF",
  primaryDarkBlue9main: "#263558",
  textBaseTertiary: "#949DB4",
  textDisabled: " #8D979C",
  brandDefault: "#2172EA",
  brandPressed: "#1955AD",
  netural200: "#949DB4",
  mainWhite: "#FFFFFF",
  backgroundDefault: "#FCFDFF",
  backgroudSelected: "#101D41",
  backgroudDisabled: "#E6E8E9",
  backgroundInverse: "#0A1126",
  foreground: "#647091",
  foreground01: "#FFFFFF",
  foreground02: "#F1F5FF",
  blueishGrey150: "#A2A9BD",
  blueishGrey75: "#F5F6F7",
  blue300: "#6DA4F8",
  blue50: "#E9F2FE",
  borderDefault: "#E3E6EC",
  border: "#DDE3EA",
  borderPressed: "#1B1B1B",
  icon02: "#647091",
  iconBrandDefault: "#2172EA",
  gray75: "#F5F5F5",
  gray200: "#D9D9D9",
  gray300: "#BFBFBF",
  navy50: "#E3E6EC",
  statusSuccessDefault: "#0ABF52",
  statusErrorDefault: "#CF1B1B",
  brightRed300: "#F06463",
  brightRed900: "#C40000",
  brightRed50: "#FFE9EC",
  yellow300: "#FFC47F",
};

export const graphColors = {
  blueGraph100: "#008BD2",
  mintGraph100: "#23E2C0",
  dblueGraph100: "#100D9F",
  vcGraph100: "#FA541C",
  tealGraph100: "#11CDE6",
  dppGraph100: "#7F398E",
  mgtGraph100: "#DC4CB4",
  dblueGraph40: "#8987D7",
  vcGraph60: "#FF9C6E",
  drdGraph60: "#E4A9A7",
  dppGraph40: "#CCB0E5",
  blueGraph40: "#97D0ED",
  mgtGraph40: "#F1B7E1",
  tealGraph20: "#B8F0F8",
  mintGraph20: "#BDF6EC",
  drdGraph100: "#BC2723",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#BEBEBE",
      ctaprimary100: "#1BB2FF",
    },
    secondary: {
      main: "#131330",
      lightBlue: "#1BB2FF",
      dimGray: "#696969",
      offGrey: "#f4f3f8",
      offWhiteB: "#f4f3f2",
      tableOffWhite: "#F7F8FA",
      nice: "blue",
      customoffwhite: "#F8F8F8",
      mainDark: "#001738",
    },
    brandPrimary: {
      main: "#1BB2FF",
      contrastText: "#fff",
    },
    gray: createColor("#747F9E"),
  } as ExtendedPaletteOptions,
  typography: {
    fontFamily: ["Inter", "Source Sans Pro", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1537,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: `${colors.borderDefault}`,
          //   color: colors.mainWhite,
          //   "& .MuiInputBase-input": {
          //     padding: "0 12px 0 12px",
          //     height: "52px",
          //   },
          //   "& .MuiInputBase-root": {
          //     borderRadius: "8px",
          //   },
        },
      },
    },

    MuiTypography: {
      variants: [
        {
          props: { variant: "span" },
          style: {
            // fontFamily: "Inter",
            // fontSize: "14px",
            // fontWeight: 600,
            // lineHeight: "22px",
            // letterSpacing: "0em",
            // textAlign: "center",
          },
        },
        {
          props: { variant: "bodySmallRegular" },
          style: {
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "20px",
            fontStyle: "normal",
            color: `${colors.textBaseTertiary}`,
          },
        },
        {
          props: { variant: "body3XXSmall" },
          style: {
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 300,
            lineHeight: "20px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#001738",
          },
        },
        {
          props: { variant: "body3XXSmall600" },
          style: {
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "20px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#001738",
          },
        },
        {
          props: { variant: "unselected" },
          style: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            letterSpacing: "0em",
            textAlign: "center",
          },
        },
        {
          props: { variant: "selected" },
          style: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "22px",
            letterSpacing: "0em",
            textAlign: "center",
          },
        },
        {
          props: { variant: "body2XSmall" },
          style: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            fontStyle: "normal",
            textAlign: "center",
            color: `${colors.text01}`,
          },
        },
        {
          props: { variant: "body2XSmallGray600" },
          style: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 600,
            color: "#8590A0",
          },
        },
        {
          props: { variant: "body2XSmallMain600" },
          style: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 600,
            color: "#001738",
          },
        },
        {
          props: { variant: "body1Small400White" },
          style: {
            width: "100%",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            color: "#ffffff",
          },
        },
        {
          props: { variant: "body2XSmallMain400" },
          style: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            color: colors.text01,
            textAlign: "center",
          },
        },
        {
          props: { variant: "bodyMedium" },
          style: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 500,
            textAlign: "center",
            fontStyle: "normal",
            lineHeight: "20px",
          },
        },
        {
          props: { variant: "body1Small400" },
          style: {
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 400,
            color: colors.text01,
            lineHeight: "24px",
            fontStyle: "normal",
          },
        },
        {
          props: { variant: "bodyLargeRegular" },
          style: {
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 400,
            color: `${colors.text01}`,
            lineHeight: "24px",
            fontStyle: "normal",
          },
        },
        {
          props: { variant: "body1Small600" },
          style: {
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            color: `${colors.text01}`,
          },
        },

        {
          props: { variant: "h3Medium" },
          style: {
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 300,
            color: "#001738",
          },
        },
        {
          props: { variant: "h3Medium600" },
          style: {
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "24px",
            fontStyle: "normal",
            color: `${colors.text01}`,
          },
        },
        {
          props: { variant: "titleSmall" },
          style: {
            fontFamily: "Inter",
            fontSize: "22px",
            fontWeight: 600,
            color: `${colors.text01}`,
            lineHeight: "32px",
            fontStyle: "normal",
            textAlign: "center",
          },
        },
        {
          props: { variant: "h1XLargeDark600" },
          style: {
            fontFamily: "Inter",
            fontSize: "24px",
            fontWeight: 600,
            color: "#001738",
          },
        },
        {
          props: { variant: "h1XLargeDark800" },
          style: {
            fontFamily: "Inter",
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 800,
            color: colors.mainDarkPrimary100,
          },
        },
        {
          props: { variant: "h1XLargeWhite800" },
          style: {
            fontFamily: "Inter",
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 800,
            color: "white",
          },
        },
        {
          props: { variant: "xxLarge800" },
          style: {
            fontFamily: "Inter",
            fontSize: "32px",
            fontWeight: 800,
            color: "#001738",
          },
        },
        {
          props: { variant: "h1XXLargeDark800" },
          style: {
            fontFamily: "Inter",
            fontSize: "3rem",
            lineHeight: "32px",
            fontWeight: 800,
            color: colors.mainDarkPrimary100,
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          "@media only screen and (max-width: 1600px)": {
            fontSize: "12px !important",
          },
          // "@media only screen and (max-width: 1440px)": {
          //   fontSize: "75%",
          // },
          // backgroundColor: "rgb(238,238,238)",
          fontSize: "16px",
        },
        body: {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          lineHeight: 1,
          borderRadius: "8px",
        },
        "*": {
          "&::-webkit-scrollbar": {
            height: ".7rem",
            width: ".5rem",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
            borderRadius: ".3rem",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#DCDCDC",
            borderRadius: ".3rem",
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          padding: "2px 4px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#F8F8F8",
          borderRadius: "12px",
          padding: "5px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          borderRadius: "5px",
          width: "fit-content",
          height: "auto",
          padding: "6px 6px",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // disableRipple: true,
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "16px",
          paddingTop: "14px",
          color: "#001738",
          backgroundColor: colors.mainWhite,
          border: "none",
          maxWidth: 550,
          justifyContent: "center",
          borderRadius: "16px",
          display: "flex",
        },
        popper: {
          padding: 0,
          // border: `none`,
          // backgroundColor: `${colors.backgroundDefault}`,
          margin: 0,
          display: "flex",
          backgroundColor: colors.mainWhite,
          justifyContent: "center",
          borderRadius: "16px",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          // paddingTop: "12px",
          border: `1px solid ${colors.border}`,
          boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
          // backgroundColor: `${colors.backgroundDefault}`,
          backgroundColor: colors.mainWhite,

          borderRadius: "16px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          color: colors.text01,
          backgroundColor: `${colors.mainWhite}`,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          height: "32px",
          padding: "4px 0 4px 16px",
          gap: "12px",
          backgroundColor: colors.foreground01,
          "&:hover": {
            backgroundColor: colors.gray75,
          },
          "&:active": {
            backgroundColor: colors.gray200,
          },
          "&.Mui-focused": {
            backgroundColor: colors.foreground01,
            border: `1px solid ${colors.borderPressed}`,
          },
          "&.Mui-disabled": {
            backgroundColor: colors.foreground01,
            color: colors.gray300,
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 40,
          height: 24,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor: colors.lightBlue,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              width: 20,
              height: 20,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              // opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 20,
            height: 20,
          },
          "& .MuiSwitch-track": {
            borderRadius: 24,
            backgroundColor: colors.icon02,
            opacity: 1,
          },
        },
      },
      variants: [
        {
          props: { size: "large" },
          style: {
            width: 52,
            height: 32,
            "& .MuiSwitch-switchBase": {
              "&.Mui-checked": {
                transform: "translateX(20px)",
              },
            },
            "& .MuiSwitch-thumb": {
              width: 28,
              height: 28,
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              width: 28,
              height: 28,
            },
          },
        },
        {
          props: { size: "medium" },
          style: {
            width: 44,
            height: 28,
            "& .MuiSwitch-thumb": {
              width: 24,
              height: 24,
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              width: 24,
              height: 24,
            },
          },
        },
        {
          props: { size: "small" },
          style: {},
        },
      ],
    },
    // MuiCheckbox: {},
    MuiButton: {
      variants: [
        {
          props: { variant: "apply" },
          style: {
            borderRadius: "100px",
            padding: "6px 16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            background: colors.brandDefault,
            gap: "8px",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
            fontStyle: "normal",
            textAlign: "center",
            color: colors.mainWhite,
            ":hover": {
              background: colors.brandDefault,
            },
            ":focus": {
              background: colors.brandPressed,
              border: `1px solid ${colors.text01}`,
              padding: "5px 15px",
            },
            ":active": {
              background: colors.brandPressed,
            },
            ":disabled": {
              background: colors.backgroudDisabled,
              color: colors.textDisabled,
            },
            "& .MuiButton-endIcon": {
              margin: 0,
            },
            "& .MuiButton-startIcon": {
              margin: 0,
            },
          },
        },
        {
          props: { variant: "clearAll" },
          style: {
            border: `none`,
            borderRadius: "16px",
            padding: "6px 16px",
            display: "flex",
            alignItems: "flex-start",
            gap: "8px",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 600,
            fontStyle: "normal",
            textAlign: "center",
            color: colors.brandDefault,
            ":hover": {
              background: colors.mainWhite,
            },
            ":active": {
              background: colors.mainWhite,
            },
            "& .MuiButton-endIcon": {
              margin: 0,
            },
            "& .MuiButton-startIcon": {
              margin: 0,
            },
          },
        },
        {
          props: { variant: "filter" },
          style: {
            // border: `1px solid ${colors.mainDarkPrimary10}`,
            borderRadius: "24px",
            justifyContent: "center",
            alignItems: "center",
            color: colors.text01,
            width: "fit-content",
            height: "40px",
            padding: "8px 8px 8px 16px",
            gap: "8px",
            border: `1px solid ${colors.borderDefault}`,
            background: colors.backgroundDefault,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 400,
            fontStyle: "normal",
            ":hover": {
              background: colors.gray75,
              border: `1px solid ${colors.borderDefault}`,
            },
            ":active": {
              background: colors.backgroundDefault,
              border: `1px solid ${colors.borderDefault}`,
            },
            "& .MuiButton-endIcon": {
              margin: 0,
            },
            "& .MuiButton-startIcon": {
              margin: 0,
            },
          },
        },
        {
          props: { variant: "netural" },
          style: {
            color: colors.brandDefault,
            textAlign: "center",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "20px",
            padding: "6px 16px !important",
            ":disabled": {
              color: colors.netural200,
            },
          },
        },
        {
          props: { variant: "neutral" },
          style: {
            border: "1px solid #E0E2E7",
            filter: "drop-shadow(0px 4px 16px rgba(27, 178, 255, 0.16))",
            borderRadius: "8px",
            padding: "1rem 2rem",
            color: "#001738",
          },
        },
        {
          props: { variant: "feedback" },
          style: {
            width: "100%",
            minHeigth: "40px",
            color: "white",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 400,
            height: "40px",
            padding: "16px 32px 16px 32px",
            ":disabled": {
              backgroundColor: "#F5F6F7",
            },
            ":hover": {
              backgroundColor: "#1BB2FF",
            },
          },
        },
        {
          props: { variant: "containedFullWidth" },
          style: {
            width: "100%",
            minHeigth: "40px",
            color: "white",
            backgroundColor: "#1BB2FF",
            height: "40px",
            paddingLeft: "12px",
            paddingRight: "12px",
            ":disabled": {
              backgroundColor: "#F5F6F7",
            },
            ":hover": {
              backgroundColor: "#233450",
            },
          },
        },
        {
          props: { variant: "outlinedFullRoundFullWidth" },
          style: {
            color: colors.brandDefault,
            backgroundColor: "white",
            height: "36px",
            borderRadius: "18px",
            border: `1px solid ${colors.brandDefault}`,
            width: "100%",
          },
        },
        {
          props: { variant: "outlinedFullRoundFullWidthDisabled" },
          style: {
            color: colors.backgroudDisabled,
            backgroundColor: "white",
            height: "36px",
            borderRadius: "18px",
            border: `1px solid ${colors.backgroudDisabled}`,
            width: "100%",
          },
        },
        {
          props: { variant: "delete" },
          style: {
            minHeigth: "40px",
            color: "white",
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 400,
            height: "40px",
            padding: "16px 32px 16px 32px",
            backgroundColor: colors.secRed100,
          },
        },
        {
          props: { size: "customMedium" },
          style: {
            borderRadius: "100px",
            padding: "12px 16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            alignContent: "center",
            gap: "8px",
            // fontSize: "16px",
            // lineHeight: "24px",
            minHeight: "36px",
            ":focus": {
              padding: "11px 15px",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "0px",
          fontSize: "14px",
          lineHeight: "normal",
          minHeigth: "40px",
          fontWeight: 400,
        },
        outlined: {
          color: "#001738",
          backgroundColor: "white",
          height: "40px",
          paddingLeft: "12px",
          paddingRight: "12px",
        },

        contained: {
          minHeigth: "40px",
          color: "white",
          backgroundColor: "#1BB2FF",
          height: "40px",
          paddingLeft: "12px",
          paddingRight: "12px",
          ":disabled": {
            backgroundColor: "#F5F6F7",
          },
          ":hover": {
            backgroundColor: "#233450",
          },
        },
        // startIcon: {
        //   margin: '0'
        // },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          display: "flex",
          padding: "auto !important",

          "& .MuiOutlinedInput-root": {
            padding: "4px",
            paddingRight: "15px!important",
            height: "46px",
          },
          "& .MuiAutocomplete-tag": {
            margin: "auto 0",
          },
        },
        paper: {
          height: "100%",
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          color: colors.mainDarkPrimary100,
          textTransform: "none",
          "&.Mui-selected": {
            color: colors.lightBlue,
            fontWeight: 600,
            //borderBottom: `2px solid ${colors.lightBlue}`,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.lightBlue,
        },
      },
    },
  },
  spacing: (sp: number) => `${0.85 * sp}rem`,
});

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    selected: true;
    span: true;
    unselected: true;
    bodySmallRegular: true;
    body2XSmall: true;
    body3XXSmall: true;
    body3XXSmall600: true;
    body2XSmallGray600: true;
    body2XSmallMain600: true;
    bodyMedium: true;
    body1Small400: true;
    body1Small400White: true;
    bodyLargeRegular: true;
    h3Medium: true;
    h3Medium600: true;
    titleSmall: true;
    h1XLargeDark600: true;
    xxLarge800: true;
    body2XSmallMain400: true;
    body1Small600: true;
    h1XLargeDark800: true;
    h1XXLargeDark800: true;
    h1XLargeWhite800: true;
  }
}

interface ExtendedPaletteOptions extends PaletteOptions {
  primary: PaletteOptions["primary"] & {
    main?: string;
    ctaprimary100?: string;
  };
  secondary: PaletteOptions["secondary"] & {
    lightBlue?: string;
    dimGray?: string;
    offGrey?: string;
    offWhiteB?: string;
    nice?: string;
    customoffwhite?: string;
    mainDark?: string;
  };
  gray: PaletteOptions;
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    neutral: true;
    netural: true;
    containedFullWidth: true;
    feedback: true;
    delete: true;
    filter: true;
    clearAll: true;
    apply: true;
    outlinedFullRoundFullWidth: true;
    outlinedFullRoundFullWidthDisabled: true;
  }
  interface ButtonPropsSizeOverrides {
    customMedium: true;
  }
  interface ButtonPropsColorOverrides {
    gray: true;
  }
  interface ButtonPropsSizeOverrides {
    customMedium: true;
  }
}
declare module "@mui/material/Switch" {
  interface SwitchPropsSizeOverrides {
    large: true;
    medium: true;
    small: true;
  }
  interface ButtonPropsColorOverrides {
    gray: true;
  }
}

export default theme;

export const getButtonVariants = () => {
  const buttonVariants = theme.components?.MuiButton?.variants || [];
  return buttonVariants.map((variant: any) => variant.props.variant);
};
