import { useState } from "react";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { titleStlAsterics } from "../sideBoard/boardStl";

const stl = {
  margin: "30px 0",
};

const titleStl = {
  fontSize: "12px",
  fontWeight: 600,
  color: "secondary.mainDark",
  marginBottom: "10.5px",
};

export default function AccountMenu(props) {
  const [value, setValue] = useState(props.selectedAccount);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (value) => {
    setValue(value);
    props.onSelect(value);
    props.setSelectedAccount(value);
  };

  return (
    <div style={{ padding: "0px" }}>
      <Box sx={stl}>
        <Box sx={{ ...titleStl, ...titleStlAsterics }}>Assign to account</Box>
        <Autocomplete
          sx={{
            width: "100%",
            "& .MuiFormControl-root": {
              backgroundColor: "#fff",
              zIndex: 1301,
            },
            "& .MuiInputBase-input": {
              padding: "0 0 0 12px !important",
              height: "42px !important",
            },
          }}
          value={value}
          getOptionLabel={(option) => option.company} //the search terms (which object prop should use for searach)
          renderTags={() => null} // this thing will disable
          onChange={(event, newValue, reason) => {
            if (reason === "clear") {
              setValue(null);
              props.onClear();
              return;
            }
            handleChange(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          options={props.availiableAccountsList}
          renderInput={(params) => {
            return <TextField {...params} placeholder="Select account for role... " />;
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>
    </div>
  );
}
