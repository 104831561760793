import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { boardWrapperStl, titleStl } from "../sideBoard/boardStl";
import { useGetAccountsQuery } from "../accountsApi";
import { SelectItemsWrapper } from "../../components/SelectItems/SelectItemsWrapper";
import { useSelector } from "react-redux";
import { ModulePermissionsCheckboxes } from "./ModulePermissionsCheckboxes";

export default function AddEntityToRole(props) {
  const { data: accounts } = useGetAccountsQuery();
  const [accountsWithSelection, setAccountsWithSelection] = useState();
  const [partnersWithSelection, setPartnersWithSelection] = useState();
  const { targetAccount } = useSelector((state) => state.users);

  const [value, setValue] = useState(props.formData.permissions);

  const handleChangeCheckbox = (moduleKey, permissionValue) => {
    const newValue = {
      ...value,
      [moduleKey]: value[moduleKey] === permissionValue ? null : permissionValue,
    };
    setValue(newValue);
    props.setFormData({ ...props.formData, permissions: newValue });
  };

  const handleSetSelectedAccounts = (newSelectedItems) => {
    setAccountsWithSelection(newSelectedItems);
    props.setFormData({
      ...props.formData,
      accounts: newSelectedItems.filter((i) => i.isSelected),
    });
  };

  const prepareDatas = (accounts) => {
    if (targetAccount) {
      let preparedAccounts = [];
      let preparedPartners = [];
      for (let i of accounts) {
        preparedAccounts.push({
          ...i,
          isSelected: props.formData.accounts.find((a) => a.id === i.id)?.isSelected || false,
        });
      }
      if (!!targetAccount?.virtualAccount) {
        preparedAccounts = preparedAccounts.filter(
          (i) => i.parentAccount === targetAccount.id || i.id === targetAccount.id,
        );
      } else if (targetAccount?.id == 0) {
        preparedAccounts = preparedAccounts.filter((i) => i.parentAccount >= targetAccount.id);
      } else {
        preparedAccounts = preparedAccounts.filter((i) => i.id === targetAccount.id);
      }
      for (let i of preparedAccounts) {
        for (let k of i.parnterData)
          preparedPartners.push({
            ...k,
            id: k.partnerId,
            isSelected: props.formData.partners.find((p) => p.id === k.partnerId)?.isSelected || false,
          });
      }
      setPartnersWithSelection(preparedPartners);
      setAccountsWithSelection(preparedAccounts);
    }
  };

  useEffect(() => {
    if (accounts.list.length > 0) prepareDatas(accounts.list);
  }, [accounts]);

  const handleSetSelectedPartners = (newSelectedItems) => {
    setPartnersWithSelection(newSelectedItems);
    props.setFormData({
      ...props.formData,
      partners: newSelectedItems.filter((i) => i.isSelected),
    });
  };

  return (
    <div>
      <div style={boardWrapperStl}>
        <Box sx={{ ...titleStl, marginBottom: "1rem" }}>Accounts:</Box>
        <SelectItemsWrapper
          itemName="account"
          itemFieldName="company"
          items={accountsWithSelection}
          setSelectedItems={handleSetSelectedAccounts}
        />
        <br />
        <Box sx={{ ...titleStl, marginBottom: "1rem" }}>Partners of accounts:</Box>
        <SelectItemsWrapper
          itemName="partner"
          itemFieldName="partnerName"
          items={partnersWithSelection}
          setSelectedItems={handleSetSelectedPartners}
        />

        <br />

        <ModulePermissionsCheckboxes value={value} handleChangeCheckbox={handleChangeCheckbox} />
      </div>
    </div>
  );
}
