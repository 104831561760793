import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SideBoardWrapper from "../sideBoard/SideBoardWrapper";
import { colors } from "../../theme";

const btnStl = {
  padding: "0.5rem 0.25rem",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    color: "secondary.mainDark",
  },
  backgroundColor: "white",
  color: colors.mainDarkPrimary100,
  border: `1px solid ${colors.mainDarkPrimary10}`,
};

export default function EditRole(props) {
  return (
    <div>
      <SideBoardWrapper
        type="edit_role_entity"
        btnIcon={<EditOutlinedIcon sx={{ fontSize: "1rem", marginRight: 0 }} />}
        headerTitle="Edit role"
        btnTitle=""
        btnStyles={{
          ...btnStl,
          width: "32px",
          height: "32px",
          padding: 0,
          minWidth: "27px",
          "& .MuiButton-startIcon": { marginLeft: "12px" },
        }}
        updateDataAndRows={() => {}}
        value={props}
        isDisabled={!props.row.canadmin}
      />
    </div>
  );
}
